@import "./bootstrap/variables.less";

// icons
@icon-font-path: "/static/fonts/";
@icon-font-name: "toolkit-entypo";


// Gray colors
@gray-base:    #000;
@gray-darker:  lighten(@gray-base, 13.5%); // #222
@gray-dark:    lighten(@gray-base, 20%);   // #333
@gray:         lighten(@gray-base, 33.5%); // #555
@gray-light:   lighten(@gray-base, 66.7%); // #777
@gray-lighter: lighten(@gray-base, 93.5%); // #eee


// Brand Colors
@brand-primary: #EEE04A;
@brand-success: #5cb85c;
@brand-info:    #5bc0de;
@brand-warning: #f0ad4e;
@brand-danger:  #FF3167;


// text
@text-color: #000;
@letter-spacing-base: -.02em;


// Spacing
@spacer:            20px;
@spacer-x:          @spacer;
@spacer-y:          @spacer;
@grid-gutter-width: 20px;


// Typography
// @font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:      "Lora", Georgia, "Times New Roman", Times, serif;
@font-weight-base:       300;
@line-height-base:       1.6;


// For h1-h6
@headings-font-family: @font-family-sans-serif;
@headings-font-weight: 700;
@headings-line-height: 1.2;
@headings-small-color: inherit;


// For .lead
@lead-font-size:      floor((@font-size-base * 1.15));
@lead-font-size-sm:   floor((@font-size-base * 1.25));
@lead-font-weight:    300;
@lead-line-height:    1.6;
@lead-letter-spacing: 0;


// Buttons
@btn-font-size:      16px;
@btn-font-weight:    700;
@btn-text-transform: none;
@btn-letter-spacing: normal;

@btn-toolbar-divider-bg: @gray-lighter;


// Container sizes
@container-desktop:       (860px + @grid-gutter-width);
@container-md:            @container-desktop;
@container-large-desktop: (1000px + @grid-gutter-width);
@container-lg:            @container-large-desktop;


// nav-bordered
@nav-bordered-color:              #fff;
@nav-bordered-color-active:       @brand-primary;
@nav-bordered-font-weight:        700;
@nav-bordered-font-weight-active: 700;


// nav header
@nav-header-color: lighten(@gray, 35%);


// Navbar
@navbar-default-bg:                #fff;
@navbar-default-link-active-color: #333;
@navbar-default-link-active-bg:    transparent;
@navbar-nav-active-font-weight:    inherit;


// Navbar - transparent
@navbar-transparent-color:               #fff;
@navbar-transparent-bg:                  transparent;
@navbar-transparent-border:              transparent;
@navbar-transparent-link-color:          @navbar-transparent-color;
@navbar-transparent-link-hover-color:    darken(@navbar-transparent-color, 10%);
@navbar-transparent-link-hover-bg:       transparent;
@navbar-transparent-link-active-color:   @navbar-transparent-link-hover-color;
@navbar-transparent-link-active-bg:      transparent;
@navbar-transparent-link-disabled-color: lighten(@navbar-transparent-color, 10%);
@navbar-transparent-link-disabled-bg:    transparent;
@navbar-transparent-brand-color:         @navbar-transparent-color;
@navbar-transparent-brand-hover-color:   darken(@navbar-transparent-color, 10%);
@navbar-transparent-brand-hover-bg:      transparent;
@navbar-transparent-toggle-hover-bg:     fade(@navbar-transparent-color, 10%);
@navbar-transparent-toggle-icon-bar-bg:  @navbar-transparent-color;
@navbar-transparent-toggle-border-color: @navbar-transparent-color;


// Jumbotron
@jumbotron-heading-font-size: ceil((@font-size-base * 4.5));


// Block
@block-color:              inherit;
@block-bg:                 #fff;
@block-color-inverted:     #fff;
@block-bg-inverted:        #000;
@block-title-font-size-sm: floor((@font-size-base * 4));
@block-title-font-size:    floor((@font-size-base * 3));
@block-lead-font-size-sm:  floor((@font-size-base * 1.5));
@block-lead-font-size:     floor((@font-size-base * 1.3));
@block-vertical-padding:   120px;


// Callouts
@callout-padding:       15px 20px;
@callout-border-radius: @border-radius-base;


// Stage
@stage-bg:                    @body-bg;
@stage-shelf-width:           250px;
@stage-toggle-offset:         20px;
@stage-toggle-zindex:         100;
@stage-toggle-padding:        4px 12px;
@stage-toggle-color:          #fff;
@stage-toggle-bg:             rgba(0,0,0,.1);
@stage-toggle-border-radius:  @border-radius-base;
@stage-toggle-hover-color:    #fff;
@stage-toggle-hover-bg:       @link-color;


// zoom
@zoom-overlay-bg: #000;
@zindex-zoom: 1080;


// Custom forms
@custom-select-color:       @input-color;
@custom-select-bg:          @input-bg;
@custom-select-disabled-bg: @input-bg-disabled;

@custom-control-indicator-bg:     @input-bg;
@custom-control-indicator-border: rgba(0,0,0,.15);

@custom-control-indicator-checked-bg:      @brand-primary;
@custom-control-indicator-checked-border:  @brand-primary;
@custom-control-indicator-active-bg:       lighten(@brand-primary, 15%);
@custom-control-indicator-active-border:   lighten(@brand-primary, 15%);
@custom-control-indicator-disabled-bg:     darken(@input-bg, 5%);
@custom-control-indicator-disabled-border: @custom-control-indicator-border;
