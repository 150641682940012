@logo-width:  120px;
@logo-height: 48px;

@font-size-h1: 48px;
@headings-font-weight: 300;

@brand-black: #294A59; //#262F36;
@text-color: #343638;

@breadcrumb-padding-vertical:   0;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-bg:                 transparent;
@breadcrumb-color:              @text-color;
@breadcrumb-active-color:       @brand-black;
@breadcrumb-separator:          ">";

@btn-border-radius-base: 1em; // round buttons

sup.footnote-ref {
  a, a:hover {
    cursor:help !important;
    color: green;
    text-decoration: none;
    // margin-right:0.3em;
    margin-left:-0.2em;
  }
  a {
    border:1px solid transparent;
  }
  a:hover{
    border:1px solid green;
    border-radius:4px;
  }
}

// sup.footnote-ref + sup.footnote-ref {

//   a, a:hover {
//     margin-left:0.3em;
//     border:1px solid red;
//   }
// }

.block-inverse {
  sup.footnote-ref{
    a, a:hover{
      color:yellow;
    }
    a:hover{
      border:1px solid yellow;
    }
  }

  .drop-shadow{
    text-shadow: 0 0 50px @brand-black,0 0 30px @brand-black,0 0 2px @brand-black;
  }

}

ol.bp-references {
  li {
    padding-bottom:0.5em;
  }
}

.text-ribbon{
  left:0 !important;
  h1, img:not(.attachment-img){
    margin-top:10px;
  }
  // border-radius: @panel-border-radius;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-ribbon-transparent-white-strong {
  background-color: rgba(255, 255, 255, 80%);
}

.text-ribbon-transparent-white {
  background-color: rgba(255, 255, 255, 60%);
}

.text-ribbon-transparent-black {
  background-color: rgba(0, 0, 0, 60%);
}

// ol.bp-references {
//   counter-reset: item;
//   margin-left:2em;
//   li {
//     display: block;
//   }
//   li:before {
//     content: counter(item) ". ";
//     counter-increment: item;
//     color: green;
//     font-weight: bold;
//     left:-1.5em;
//   }
// }

// @font-family-sans-serif: "San Francisco", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// @headings-font-family: "San Francisco", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

@font-family-sans-serif: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
@headings-font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

@navbar-inverse-bg: @brand-black;


.block {
  p, li {
    font-size: floor(@font-size-base*1.20);
  }
}

.autoclick{
  cursor: pointer;
}

// body { padding-top: @logo-height+4; }

a.navbar-brand #logo {
  background-image:url(/BP-Logo-mono-white.svg);
  background-size: @logo-width @logo-height;
  background-repeat: no-repeat;
  width: @logo-width+4;
  height: @logo-height;
  margin-top:-13px;
}

.navbar {
    min-height: @logo-height+4;
    margin-bottom: 0;
    border:0;
}

.navbar-nav {
  .active {
    border-bottom: 2px solid @brand-primary;
  }
  >li {
    .dropdown-menu {
      margin-top: 2px;
      background-clip: inherit !important;
      background-color: lighten(@brand-black, 10%);
      :hover{
        background-color: lighten(@brand-black, 20%);
      }
      li {
        a {
          color:@gray-light;
        }
      }
    .divider {
      background-color: @brand-black;
    }
    }
  }
}

/* navbar */
.app-navbar {
  font-weight: 500;
  // letter-spacing: .05em;
  // position: absolute;
}


/* header block */
.app-graph {
  width: 150%;
  position: absolute;
  right: 0;
  bottom: -40px;
}
@media (min-width: 768px) {
  .app-graph {
    width: 100%;
    bottom: 0;
  }
}


/* iphone block */
.app-iphone-block {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .app-iphone-block {
    padding-bottom: 0;
  }
}


/* code block */
.app-code-block {
  background-color: #262F36;
  overflow: hidden;
}
.app-code {
  color: #6EA4CF;
  border: #495158 1px solid;
  background: transparent;
  font-size: 12px;
  padding: 20px;
  line-height: 1.55;
  margin-bottom: 30px;
}
.app-code span {
  color: #8496A5;
}
@media (min-width: 768px) {
  .app-code {
    width: 600px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .app-code {
    padding: 50px;
    font-size: 15px;
  }
}


/* pull quote block */
.app-high-praise img {
  width: 100%;
}


/* brand ribbon block */
.app-ribbon {
  background: #2595FF;
}
.app-ribbon .container {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: -30px;
}
.app-ribbon img {
  margin: 0 10px 30px;
}


/* marketing grid block */
.app-block-marketing-grid .container {
  margin-bottom: -60px;
}
.app-marketing-grid .m-b {
  margin-bottom: 10px !important;
}
@media (max-width: 768px) {
  .app-marketing-grid .m-b-lg {
    margin-bottom: 40px !important;
  }
}


/* price plan block */
.app-price-plans {
  background-color: #F4F5F6;
}
.app-price-plans .container {
  margin-bottom: -60px;
}


/* footer block */
.app-footer {
  background-color: @brand-black;
  a {
    color: #ddd;
    // font-weight: 600;
  }

  h6 {
    a {
    font-weight: 900;
  }}


  a.btn-primary{
    color:#fff;
  }

  li {
    a {
      font-size: @font-size-base;
    }
    li a {
      font-size: @font-size-base * 0.90;
    }
  }

  hr {
    margin-top:    3px;
    margin-bottom: 1px;
    border:0;
    border-top: 1px solid #ddd;
    // border-top: 1px solid lighten(@brand-black, 10%);
  }

  ul {
    hr {
      // border-top: 1px solid darken(@brand-black, 5%);
      border-top: 1px solid lighten(@brand-black, 10%);
      // border-top: 1px solid fade(@brand-primary, 50%);
      // border-top: 1px solid #999;
      // border-top: 1px dotted lighten(@brand-black, 30%);
      // border-top: 1px solid @brand-black;
    }
  }

}


/* general block overrrides */
@media (max-width: 768px) {
  .block .lead {
    line-height: 1.3;
  }
  .block h3 {
    line-height: 1.15;
  }
}


/* general inverse overrides */
.block-inverse .text-muted {
  color: rgba(255, 255, 255, .7);
}
.block-inverse .block-bg-img {
  background-color: transparent;
}


/* general btn overrides */
.btn-lg {
  font-size: 18px;
}
.block-secondary .btn-lg {
  width: 100%;
}
.btn-lg:not(.btn-block) {
  padding: 12px 50px;
}
@media (min-width: 768px) {
  .btn-lg:not(.btn-block) {
    width: auto;
  }
}


/* ie10+ */
@media (min-width: 768px) {
  .app-align-center {
    display: flex;
    align-items: center;
  }
}

/* Bouncing Chevron */

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-2*@spacer);
    transform: translateY(-2*@spacer);
  }
  60% {
    -webkit-transform: translateY(-1*@spacer);
    transform: translateY(-1*@spacer);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-2*@spacer);
  }
  60% {
    transform: translateY(-1*@spacer);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(-2*@spacer);
    transform: translateY(-2*@spacer);
  }
  60% {
    -ms-transform: translateY(-1*@spacer);
    transform: translateY(-1*@spacer);
  }
}

span.chevron-next {
  font-size: 60px;
  color: rgba(255, 255, 255, .7);
  position:absolute;
  bottom:@spacer*4;
  // -webkit-animation: bounce 2s infinite;
  // animation: bounce 2s infinite;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  &:before {
    background-color: #eeeeee;
    bottom: 0;
    content: " ";
    left: 50%;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}
.timeline>li {
  margin-bottom: 20px;
  position: relative;
  &:before {
    content: " ";
    content: " ";
    display: table;
    display: table;
  }
  &:after {
    clear: both;
    clear: both;
    content: " ";
    content: " ";
    display: table;
    display: table;
  }
}

.timeline>li>.timeline-panel {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  border-radius: 2px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  float: left;
  opacity : 0.9;
  padding: 20px;
  position: relative;
  width: 45%;
  &:before {
    border-bottom: 15px solid transparent;
    border-left: 15px solid #cccccc;
    border-right: 0 solid #cccccc;
    border-top: 15px solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    right: -15px;
    top: 26px;
  }
  &:after {
    border-bottom: 14px solid transparent;
    border-left: 14px solid #ffffff;
    border-right: 0 solid #ffffff;
    border-top: 14px solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    right: -14px;
    top: 27px;
  }
}

.block-inverse {
  .timeline>li>.timeline-panel {
    background-color : #343638;
  }
}


.timeline>li>.timeline-badge {
  background-color: #999999;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  color: #ffffff;
  font-size: 1.4em;
  height: 50px;
  left: 50%;
  line-height: 50px;
  margin-left: -25px;
  position: absolute;
  text-align: center;
  top: 16px;
  width: 50px;
  z-index: 100;
}
.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  &:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  &:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.timeline-fullwidth {

  ul.timeline {
    &:before {
      left: 40px;
    }
  }
  ul.timeline>li>.timeline-panel {
    float: right;
    width: 80%;
    &:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
    }
    &:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }
  }
  ul.timeline>li>.timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }
}


.timeline-badge.primary {
  // background-color: #2e6da4 !important;
  background-color: @brand-primary !important;
}
.timeline-badge.success {
  background-color: @brand-success !important;
}
.timeline-badge.warning {
  background-color: @brand-warning !important;
}
.timeline-badge.danger {
  background-color: @brand-danger !important;
}
.timeline-badge.info {
  background-color: @brand-info !important;
}
.timeline-title {
  color: inherit;
  margin-top: 0;
}
.timeline-body>p {
  font-size: @font-size-base !important;
  margin-bottom: 0;
}
.timeline-body>ul {
  font-size: @font-size-base !important;
  margin-bottom: 0;
  li {
    font-size: @font-size-base !important;
    margin-bottom: 0;
  }
}

.timeline-body>p+p {
  margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
      width: 80%;
        // width: calc(100%-90px);
        // width: -moz-calc(100%-90px);
        // width: -webkit-calc(100%-90px);
    }

    ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

        ul.timeline > li > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        ul.timeline > li > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }
}

.gradient-gray-to-blue{
background: #b3c6ce;
background: -moz-linear-gradient(top,  #b3c6ce 0%, #46a3d2 100%);
background: -webkit-linear-gradient(top,  #b3c6ce 0%,#46a3d2 100%);
background: linear-gradient(to bottom,  #b3c6ce 0%,#46a3d2 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3c6ce', endColorstr='#46a3d2',GradientType=0 );
}

.va-top {
  vertical-align: top;
}

.btn-white-outline {
  .button-outline-variant(#fff);
}

.block-inverse {
  .btn-white-outline {
    .button-outline-variant(#fff);

    &:hover,
    &:focus {
      color: #333;
    }

  }
}

.btn-black-outline {
  .button-outline-variant(#333);
}

.btn-gray-outline {
  .button-outline-variant(#999);
}

.label-black {
  .label-variant(@brand-black);
}



.attachment-block {
  text-align: center;
  a, a p {
    color: lighten(@brand-black, 20%);
    font-size: 14px;
  }

  .attachment-image {
    margin-top: 16px;
    margin-bottom: 16px;

    display:inline-block !important;
    position:relative !important;
    overflow: hidden;

    a {
      color: transparent;
    }

    .attachment-image-overlay {
      visibility:hidden;
      opacity:0;
      transition: visibility 0s linear 1s, opacity 0.5s linear;
      font-size: 600%;

      span {
        margin: auto;
        position: absolute;
        top: 25%; left: 0; bottom: 0; right: 0;
      }
    }

    a:hover .attachment-image-overlay {
      visibility:visible;
      opacity:1;
      transition-delay: 0s;
      text-align:center;
      background-color : rgba(70, 163, 210, 0.3); // #46A3D2
      position: absolute;
      text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
      color: #fff;
      width:100%;
      height:100%;
    }

  }
  .attachment-image-border{
    img {
      border: 1px solid @brand-black;
    }
  }

  .attachment-image-shadow  {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }
}

/** PDF Thumbnal box **/

.pdf-thumb-box
{
  display:inline-block !important;
  position:relative !important;
  overflow: hidden;
  a{
    color:transparent;
  }
  a:hover .pdf-thumb-box-overlay {
      visibility:visible;
      opacity:1;
      transition-delay:0s;
      text-align:center;
      position: absolute;
      background-color: rgba(255, 0, 0, 0.58);
      color: #fff;
      width:100%;
      height:100%;
      text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  }
}

.pdf-thumb-box-overlay {
  visibility:hidden;
  opacity:0;
  transition:visibility 0s linear 0.5s,opacity 0.5s linear;
  span {
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0
  }
}

.color-table {

    td.cell-square:before {
        content:"◼︎";
    }

    td.cell-check:before {
        content:"✔︎";
    }

    td.cell-cross:before {
        content:"✗";
    }

    td.cell-green, td.cell-orange, td.cell-red, td.cell-gray{
        font-size:200%;
        text-align: center;
        vertical-align: middle;
    }

    td.grayed, th.grayed {
        background-color:#fafafa;
        width:10%;
        font-size:90%;
        h4 {
          font-size:100%;
        }
    }


    td.cell-text {
        font-size:100%;
    }

    td.cell-gray{
        color: #ccc;
    }

    td.cell-green{
        color: green;
    }

    td.cell-orange{
        color: orange;
    }

    td.cell-red{
        color: red;
    }

    td,th {
      width:20%;
      vertical-align:middle !important;
      text-align:center;
    }

    tr.row-grade-0 {
      background-color: #0FFF51;
    }

    tr.row-grade-1 {
      background-color: #82E80C;
    }

    tr.row-grade-2 {
      background-color: #FFE000;
    }

    tr.row-grade-3 {
      background-color: #E8960C;
    }

    tr.row-grade-4 {
      background-color: #FF4700;
    }

}

.table-row-variant(grade0; #0FFF51);
.table-row-variant(grade1; #82E80C);
.table-row-variant(grade2; #FFE000);
.table-row-variant(grade3; #E8960C);
.table-row-variant(grade4; #FF4700);


