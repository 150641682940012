// Bootstrap mixins
@import "./bootstrap/mixins.less";
@import "./custom/mixins.less";

// Reset and dependencies
@import "./bootstrap/normalize.less";
@import "./bootstrap/print.less";

// Core CSS
@import "./bootstrap/scaffolding.less";
@import "./bootstrap/type.less";
@import "./bootstrap/code.less";
@import "./bootstrap/grid.less";
@import "./bootstrap/tables.less";
@import "./bootstrap/forms.less";
@import "./bootstrap/buttons.less";

// Components
@import "./bootstrap/component-animations.less";
@import "./bootstrap/dropdowns.less";
@import "./bootstrap/button-groups.less";
@import "./bootstrap/input-groups.less";
@import "./bootstrap/navs.less";
@import "./bootstrap/navbar.less";
@import "./bootstrap/breadcrumbs.less";
@import "./bootstrap/pagination.less";
@import "./bootstrap/pager.less";
@import "./bootstrap/labels.less";
@import "./bootstrap/badges.less";
@import "./bootstrap/jumbotron.less";
@import "./bootstrap/thumbnails.less";
@import "./bootstrap/alerts.less";
@import "./bootstrap/progress-bars.less";
@import "./bootstrap/media.less";
@import "./bootstrap/list-group.less";
@import "./bootstrap/panels.less";
@import "./bootstrap/responsive-embed.less";
@import "./bootstrap/wells.less";

// Extended Bootstrap components
@import "./custom/type-custom.less";
@import "./custom/alerts-custom.less";
@import "./custom/buttons-custom.less";
@import "./custom/nav-heading.less";
@import "./custom/navbar-utilities.less";

// Components w/ JavaScript
@import "./bootstrap/modals.less";
@import "./bootstrap/tooltip.less";
@import "./bootstrap/popovers.less";
@import "./bootstrap/carousel.less";

// Utility classes
@import "./bootstrap/utilities.less";
@import "./bootstrap/responsive-utilities.less";

// Custom theme components
@import "./custom/icons.less";
@import "./custom/utilities-spacer.less";
@import "./custom/text-inherit.less";
@import "./custom/nav-bordered.less";
@import "./custom/buttons-outline.less";
@import "./custom/buttons-radius.less";
@import "./custom/forms-custom.less";
@import "./custom/container-custom.less";
@import "./custom/block.less";
@import "./custom/navbar-transparent.less";
@import "./custom/carousel-light.less";
@import "./custom/callouts.less";
@import "./custom/featured-list.less";
@import "./custom/zoom.less";
@import "./custom/text-ribbon.less";
@import "./custom/iconlist.less";
@import "./custom/panels-bold.less";
@import "./custom/statcard.less";
@import "./custom/pull-quote.less";
@import "./custom/stage.less";


//
// Custom styles
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

  &.text-uppercase {
    letter-spacing: .15em;
  }

  small,
  .small {
    font-weight: inherit;
  }
}

h6, .h6 {
  margin-bottom: (@spacer-y * .25);
  color: lighten(@gray, 40%);
  text-transform: uppercase;
}
